import { AuthenticationResult, Configuration, EventType, PublicClientApplication } from '@azure/msal-browser';

const b2cPolicies = {
  names: {
    signUpSignIn: import.meta.env.VITE_AUTH_USERFLOW_NAME,
    forgotPassword: import.meta.env.VITE_AUTH_FORGOT_FLOW,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${import.meta.env.VITE_AUTH_TENANT_NAME}.b2clogin.com/${import.meta.env.VITE_AUTH_TENANT_NAME}.onmicrosoft.com/${import.meta.env.VITE_AUTH_USERFLOW_NAME}`,
    },
  },
  authorityDomain: `${import.meta.env.VITE_AUTH_TENANT_NAME}.b2clogin.com`,
};

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_AUTH_PUBLIC_CLIENT_ID, // Client Id of the app registration
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    claimsBasedCachingEnabled: true, // If true, access tokens will be cached under a key containing the hash of the requested claims string
  },
};

export const loginRequest = {
  scopes: ['openid'],
};

export const resetPasswordRequest = {
  authority: `https://${import.meta.env.VITE_AUTH_TENANT_NAME}.b2clogin.com/${import.meta.env.VITE_AUTH_TENANT_NAME}.onmicrosoft.com/${import.meta.env.VITE_AUTH_FORGOT_FLOW}`,
  scopes: ['openid'],
}

export const tokenRequestBase = {
  scopes: [`https://${import.meta.env.VITE_AUTH_TENANT_NAME}.onmicrosoft.com/${import.meta.env.VITE_AUTH_SERVER_SCOPE}/api`],
};


const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  const account = msalInstance.getAllAccounts()?.[0];
  if (account) {
    msalInstance.setActiveAccount(account);
  }
}

msalInstance.addEventCallback(message => {
  if (message.eventType === EventType.LOGIN_SUCCESS && (message.payload as AuthenticationResult).account) {
    const account = (message.payload as AuthenticationResult).account;
    msalInstance.setActiveAccount(account);
  }
});

export { msalInstance };