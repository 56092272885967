import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const InfoBullets: FC = () => {

  const { t } = useTranslation('input');
  return (
    <ul >
      <li>{t('form.emissionReport.info.bullet_1')}</li>
      <li>{t('form.emissionReport.info.bullet_2')}</li>
      <li>{t('form.emissionReport.info.bullet_3')}</li>
    </ul>
  );
};