import { Relatie } from '@/lib/types';
import { Typography, Form, Select, Radio, Space } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Waterlozing: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const form = Form.useFormInstance<Relatie>();

  const geenNullozing = [
    Form.useWatch(['controle', 'zandfilterDrainage'], form),
    Form.useWatch(['controle', 'incidenteleLozing'], form),
    Form.useWatch(['controle', 'calamiteit'], form),
    Form.useWatch(['controle', 'waterOverschot'], form),
    Form.useWatch(['controle', 'drainageLozing'], form),
  ].some(bool => bool === true);

  const isGrondTeelt = [0].includes(Form.useWatch(['teeltwijze'], form));
  const isSubstraatTeelt = [1].includes(Form.useWatch(['teeltwijze'], form));
  const isCombiTeelt = [2].includes(Form.useWatch(['teeltwijze'], form));

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.waterdischarge.title')}
      </Title>
      <Typography>
        {isGrondTeelt && t('form.waterdischarge.topTextGrondteelt')}
        {isSubstraatTeelt && t('form.waterdischarge.topTextSubstraatteelt')}
        {isCombiTeelt && t('form.waterdischarge.topTextCombiteelt')}
      </Typography>
      <Form.Item
        name="waterLozingSituatie"
        rules={[{ required: true, message: t('form.waterdischarge.requiredMessage') }]}
      >
        <Radio.Group >
          <Space size={'middle'} direction="vertical">
            <Radio value={8}>{t('form.waterdischarge.option_1')}</Radio>
            <Radio value={6}>{t('form.waterdischarge.option_2')}</Radio>
            <Radio value={7}>{t('form.waterdischarge.option_3')}</Radio>
            <Radio value={1}>{t('form.waterdischarge.option_4')}</Radio>
            <Radio value={2}>
              {isGrondTeelt && t('form.waterdischarge.option_5_grondteelt')}
              {isSubstraatTeelt && t('form.waterdischarge.option_5_substraatteelt')}
              {isCombiTeelt && t('form.waterdischarge.option_5_combiteelt')}
            </Radio>
            <Radio value={9}>
              {isGrondTeelt && t('form.waterdischarge.option_6_grondteelt')}
              {isSubstraatTeelt && t('form.waterdischarge.option_6_substraatteelt')}
              {isCombiTeelt && t('form.waterdischarge.option_6_combiteelt')}
            </Radio>
            <Radio value={4}>
              {isGrondTeelt && t('form.waterdischarge.option_7_grondteelt')}
              {isSubstraatTeelt && t('form.waterdischarge.option_7_substraatteelt')}
              {isCombiTeelt && t('form.waterdischarge.option_7_combiteelt')}
            </Radio>
            <Radio value={5} disabled={geenNullozing}>
              {t('form.waterdischarge.option_8')}
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};