import React, { PropsWithChildren, createContext } from 'react';
import { FeatureFlags } from '../types';

export const FeatureFlagContext = createContext<FeatureFlags>({ 'show-maintenance-mode': false, 'show-manual-input': true });

export const useFeatureFlagContext = (): FeatureFlags => React.useContext(FeatureFlagContext);

export const FeatureFlagProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  // Currently we use hard-coded features - as we do not have a feature manager yet.
  // Using this hard-coded features it does give the oppertunity to keep one main branch.
  const data: FeatureFlags = {
    'show-maintenance-mode': import.meta.env.VITE_F_MAINTENANCE_MODE !== 'false',
    'show-manual-input': import.meta.env.VITE_F_MANUAL_INPUT === 'true',
    'show-deelrapportage-button': import.meta.env.VITE_F_DEELRAPPORTAGE === 'true',
    'show-editrapportage-button': import.meta.env.VITE_F_EDITRAPPORTAGE === 'true'
  };

  return <FeatureFlagContext.Provider value={data}>{children}</FeatureFlagContext.Provider>;
};