import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchAuthenticatedForJson } from '../utils';
import { Afzender, Relatie } from '../types/relatie';

const QUERY_KEY = ['input'];
const BASE_URL = import.meta.env.VITE_INPUT_API_URL;

export const useGetHandmatigeInvoer = (year?: string): UseQueryResult<Relatie> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'rapportage', year],
    queryFn: () => fetchAuthenticatedForJson<Relatie>(`${BASE_URL}/input/${year}`),
    enabled: year != undefined,
    retry: false,
    staleTime: 0
  });

export const useGetDeskundige = (): UseQueryResult<{ label: string; value: string }[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'deskundige'],
    queryFn: () => fetchAuthenticatedForJson<{ label: string; value: string }[]>(`${BASE_URL}/input/deskundige`),
  });
export const useGetSectoren = (): UseQueryResult<{ code: string; omschrijving: string }[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'sectoren'],
    queryFn: () => fetchAuthenticatedForJson<{ code: string; omschrijving: string }[]>(`${BASE_URL}/input/sectoren`),
  });
export const useGetGewassen = (): UseQueryResult<{ code: string; omschrijving: string; sector: { code: string; omschrijving: string } }[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'gewassen'],
    queryFn: () => fetchAuthenticatedForJson<{ code: string; omschrijving: string; sector: { code: string; omschrijving: string; } }[]>(`${BASE_URL}/input/gewassen`),
  });

export const useSendHandmatigeInvoer = (): UseMutationResult<Afzender, Error, Afzender> => {
  return useMutation<Afzender, Error, Afzender>({
    mutationFn: (newManualInput: Afzender) =>
      fetchAuthenticatedForJson<Afzender>(`${BASE_URL}/input`, {
        json: newManualInput,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useValidate = (): UseMutationResult<Afzender, Error, Afzender> => {
  return useMutation<Afzender, Error, Afzender>({
    mutationFn: (newManualInput: Afzender) =>
      fetchAuthenticatedForJson<Afzender>(`${BASE_URL}/input/validate`, {
        json: newManualInput,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY],
  });
};