import { FileTextOutlined, FormOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './core.module.scss';
import { Roles, tokenContainsRole } from '@/lib/context';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

interface SiderProps {
  selectedKey: string;
  onSelect: (key: string) => void;
}

function getItem(label: string, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem {
  return {
    label,
    key,
    icon: icon ? icon : null,
    children,
    type,
  } as MenuItem;
}

export const SideNav: React.FunctionComponent<SiderProps> = ({ selectedKey, onSelect }) => {
  const { t } = useTranslation('core');

  const [collapsed, setCollapsed] = useState(false);

  const showManualInput = tokenContainsRole([Roles.Administrator, Roles.Deelnemer, Roles.Dienstverlener]);
  const showDeelnemerRedirect = tokenContainsRole([Roles.Administrator, Roles.Bevoegdgezag]);

  const items: MenuProps['items'] = [
    getItem(t('menu.home'), '/', <HomeOutlined />),
    getItem(t('menu.reports.title'), '/reports', <FileTextOutlined />, [
      getItem(t('menu.reports.environment'), '/reports/environment'),
      getItem(t('menu.reports.request'), '/reports/request'),
    ]),
  ];

  if (showManualInput) {
    items.push(
      getItem(t('menu.reports.manualInput'), '/manual-input', <FormOutlined />)
    );
  }

  if (showDeelnemerRedirect) {
    const backofficeUrl = import.meta.env.VITE_BACKOFFICE_URL;

    if (backofficeUrl !== undefined) {
      items.push({
        label: t('menu.management'),
        key: '',
        onClick: () => {
          window.location.href = `${backofficeUrl}/deelnemer`;
        },
        icon: <UserOutlined />,
      });
    }
  }

  return (
    <Sider className={styles.sideNav} collapsed={collapsed} width={250} onCollapse={value => setCollapsed(value)} collapsible breakpoint="lg">
      <Menu selectedKeys={[selectedKey]} defaultSelectedKeys={[selectedKey]} onSelect={e => onSelect(e.key)} mode="inline" items={items} />
    </Sider>
  );
};