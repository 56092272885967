import { Page } from '@/lib/components/core/pageItems/page';
import { useGetHandmatigeInvoer, useSendHandmatigeInvoer, useValidate } from '@/lib/queries/manual_input';
import { Form, Space, Steps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { StepProps } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step1 } from './step_1/page';
import { Step2 } from './step_2/page';
import { Step3 } from './step_3/page';
import { Step4 } from './step_4/page';
import { Step5 } from './step_5/page';
import { Afzender, RapportageType, Relatie } from '@/lib/types/relatie';
import { v4 as uuid } from 'uuid'
import { useMsal } from '@azure/msal-react';
import ResponseModal from './modals/responsemodal';
import { useNavigate } from 'react-router-dom';

export const ManualInputPage: React.FunctionComponent = () => {
  const { t } = useTranslation('input');
  const [form] = useForm<Relatie>();
  const navigate = useNavigate();

  const registratieJaar = Form.useWatch('registratiejaar', form);

  const [currentStep, setCurrentStep] = useState(0);
  const [responseContent, setResponseContent] = useState<string | null>(null);
  const [modalErrorContent, setModalErrorContent] = useState<string | null>(null);

  const [formAfzender, setFormAfzender] = useState<Afzender>({} as Afzender);

  const { instance: msalInstance } = useMsal();
  const { mutate: mutateHandmatigeInvoer, isPending: isSaving } = useSendHandmatigeInvoer();
  const { mutate: validate, isPending: isValidating } = useValidate();
  const { data: handmatigeInvoerData, isLoading: handmatigeInvoerIsLoading, isError: handmatigeInvoerIsError } = useGetHandmatigeInvoer(registratieJaar);

  useEffect(() => {
    if (handmatigeInvoerData) {
      form.setFieldsValue(handmatigeInvoerData);
    }
  }, [handmatigeInvoerData, form]);

  const [items, setItems] = useState<StepProps[]>([
    {
      status: 'process',
      title: 'Introductie',
      disabled: false,
    },
    {
      status: 'wait',
      title: 'Bedrijfsgegevens',
      disabled: true,
    },
    {
      status: 'wait',
      title: 'Milieugegevens',
      disabled: true,
    },
    {
      status: 'wait',
      title: 'Teeltplan',
      disabled: true,
    },
    {
      status: 'wait',
      title: 'Versturen',
      disabled: true,
    },
  ]);

  const updateStep = (page: number) => {
    setItems(current => {
      const updated = [...current];
      updated[page].status = 'finish';
      updated[page].disabled = page === 0

      if (updated[page + 1].status !== 'finish') {
        updated[page + 1].status = 'process';
        updated[page + 1].disabled = false
      }
      return updated;
    });
  };

  function updateFormAfzenderUsingFormFields() {
    const currentValues = form.getFieldsValue();
    setFormAfzender(prev => ({
      ...prev,
      relatie: [{
        ...prev.relatie?.[0],
        ...currentValues
      }]
    }));
  }

  const validateFormSaveAndNext = async () => {
    form
      .validateFields()
      .then(() => {
        updateFormAfzenderUsingFormFields();
        updateStep(currentStep);
        setCurrentStep(currentStep + 1);
      })
  };

  const onSubmitStep1 = async () => {
    validateFormSaveAndNext();
  }

  const onSubmitStep2 = async () => {
    validateFormSaveAndNext();
  }

  const onSubmitStep3 = async () => {
    try {
      form.validateFields()
        .then(() => {

          // Update afzender state using the form values
          const currentValues = form.getFieldsValue();
          setFormAfzender(prev => ({
            ...prev,
            relatie: [{
              ...prev.relatie?.[0],
              ...currentValues,
            }],
          }));

          // Perform actions after afzender has been updated
          setFormAfzender(prev => {
            const updatedRelatie = prev.relatie?.[0];
            const sanitizedRelatie = {
              ...updatedRelatie,
              ...sanitizeMetingen(updatedRelatie),
              ...sanitizeLozingsVorm(updatedRelatie),
              ...sanitizeLozingen(updatedRelatie),
            };

            // Add additional fields
            const account = msalInstance.getActiveAccount();
            const username = account?.username || 'unknown';

            const newAfzender = {
              ...prev,
              berichtVersie: '03.00',
              berichtID: uuid(),
              timestamp: new Date().toISOString(),
              isUpdate: true,
              loginNaam: username.toString(),
              isMilieugegevensValidatie: true,
              isTeeltplanRegelsValidatie: false,
              relatie: [sanitizedRelatie],
            };

            // Call validate endpoint with the updated afzender
            validate(newAfzender, { // milieugegevens should be same
              onSuccess: (response: any) => {
                if (response?.relaties?.some((relatie) => relatie?.foutBerichten?.some((foutBericht) => foutBericht?.code === 0))) {
                  validateFormSaveAndNext();
                } else {
                  // Set the modal with the results
                  setResponseContent(JSON.stringify(response, null, 2));
                }

              },
              onError: (error) => {
                setResponseContent(error.message);
                setModalErrorContent(
                  'Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.'
                );
              },
            });

            return newAfzender;
          });
        })

    } catch (e) {
      // Handle validation errors or other exceptions
      setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.');
    }
  };

  const onSubmitStep4 = async () => {
    try {
      form.validateFields()
        .then(() => {

          // Update afzender state using the form values
          const currentValues = form.getFieldsValue();
          setFormAfzender(prev => ({
            ...prev,
            relatie: [{
              ...prev.relatie?.[0],
              ...currentValues,
            }],
          }));

          // Perform actions after afzender has been updated
          setFormAfzender(prev => {
            const updatedRelatie = prev.relatie?.[0];
            const sanitizedRelatie = {
              ...updatedRelatie,
              ...sanitizeMetingen(updatedRelatie),
              ...sanitizeLozingsVorm(updatedRelatie),
              ...sanitizeLozingen(updatedRelatie),
            };

            // Add additional fields
            const account = msalInstance.getActiveAccount();
            const username = account?.username || 'unknown';

            const newAfzender = {
              ...prev,
              berichtVersie: '03.00',
              berichtID: uuid(),
              timestamp: new Date().toISOString(),
              isUpdate: true,
              isTeeltplanRegelsValidatie: true,
              isMilieugegevensValidatie: false,
              loginNaam: username.toString(),
              relatie: [sanitizedRelatie],
            };

            // Call the mutation with the updated afzender
            validate(newAfzender, {
              onSuccess: (response: any) => {
                if (response?.relaties?.some((relatie) => relatie?.foutBerichten?.some((foutBericht) => foutBericht?.code === 0))) {
                  validateFormSaveAndNext();
                } else {
                  // Set the modal with the results
                  setResponseContent(JSON.stringify(response, null, 2));
                }

              },
              onError: (error) => {
                setResponseContent(error.message);
                setModalErrorContent(
                  'Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.'
                );
              },
            });

            return newAfzender;
          });
        })

    } catch (e) {
      // Handle validation errors or other exceptions
      setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.');
    }
  }


  const onSubmitStep5 = async (rapportageType: RapportageType) => {
    form
      .validateFields()
      .then(() => {
        if (currentStep === 4) {
          setFormAfzender(prev => {
            const currentValues = form.getFieldsValue();
            const updatedRelatie = {
              ...prev.relatie?.[0],
              ...currentValues
            };

            const account = msalInstance.getActiveAccount();
            const username = account?.username || 'unknown';

            return {
              ...prev,
              berichtVersie: '03.00',
              berichtID: uuid(),
              timestamp: new Date().toISOString(),
              isUpdate: true,
              isDeelRapportage: rapportageType,
              isTeeltplanRegelsValidatie: true,
              isMilieugegevensValidatie: false,
              loginNaam: username.toString(),
              relatie: [updatedRelatie],
            };
          });
          form.submit();
        }
      })
  }

  function sanitizeLozingen(relatie: Relatie) {
    let lozingen = relatie.lozingen?.filter(l => l !== undefined && l !== null);

    if (lozingen === null || lozingen === undefined) {
      return;
    }

    relatie.lozingsVorm.forEach(element => {
      if (element.value == 0 || element.value == 4) {
        relatie.lozingen = relatie.lozingen.filter(m => m.betrekkingTotTeeltwijze !== element.betrekkingTotTeeltwijze)
        let lozingen = Array.from({ length: 13 }, (_, index) => ({
          periode: index + 1,
          hoeveelheid: 0,
        }))
        relatie.lozingen.push({
          betrekkingTotTeeltwijze: element.betrekkingTotTeeltwijze,
          lozingen: lozingen
        })
      }
    });
    return {
      lozingen: relatie.lozingen
    }
  }

  function sanitizeLozingsVorm(relatie: Relatie) {
    let lozingsVormen = relatie.lozingsVorm?.filter(l => l !== undefined && l !== null);

    if (lozingsVormen === null || lozingsVormen === undefined) {
      return;
    }

    return {
      lozingsVorm: lozingsVormen
    }
  }

  function sanitizeMetingen(relatie: Relatie) {
    let metingen = relatie.metingen?.filter(m => m !== undefined && m !== null);

    if (metingen === null || metingen === undefined) {
      return;
    }

    for (let metingenIndex = 0; metingenIndex < metingen.length; metingenIndex++) {
      let meting = metingen[metingenIndex];
      for (let metingIndex = 0; metingIndex < meting.nH4Metingen.length; metingIndex++) {
        let nh4Meting = meting.nH4Metingen[metingIndex];
        if (meting.nO3Metingen[metingIndex] !== null) {
          meting.nO3Metingen[metingIndex].datum = nh4Meting.datum
        }
        if (meting.pMetingen[metingIndex] !== null) {
          meting.pMetingen[metingIndex].datum = nh4Meting.datum
        }
      }

      meting.nH4Metingen = meting.nH4Metingen.filter((meting) => meting.datum !== null && meting.gehalte !== null && meting.gehalte !== undefined);
      meting.nO3Metingen = meting.nO3Metingen.filter((meting) => meting.datum !== null && meting.gehalte !== null && meting.gehalte !== undefined);
      meting.pMetingen = meting.pMetingen.filter((meting) => meting.datum !== null && meting.gehalte !== null && meting.gehalte !== undefined);
    }

    relatie.lozingsVorm.forEach(element => {
      if (element.value == 0 || element.value == 4) {
        relatie.metingen = relatie.metingen.filter(m => m.betrekkingTotTeeltwijze !== element.betrekkingTotTeeltwijze)
        relatie.metingen.push({
          betrekkingTotTeeltwijze: element.betrekkingTotTeeltwijze,
          aantalMetingenPerJaar: 0,
          isBerekend: false,
          nH4Metingen: [],
          nO3Metingen: [],
          pMetingen: []
        })
      }
    });

    return {
      metingen: relatie.metingen
    }
  }

  const onModalOk = () => {
    setResponseContent(null);
    setModalErrorContent(null);

    let responseData = JSON.parse(responseContent || '');

    if (currentStep === 4) {
      if (responseData?.relaties?.some((relatie) => relatie?.foutBerichten?.some((foutBericht) => foutBericht?.code === 0 || foutBericht?.code === 4))) {
        navigate('/')
        return;
      }
    } else {
      if (responseData?.relaties?.some((relatie) => relatie?.foutBerichten?.some((foutBericht) => foutBericht?.code === 4))) {
        validateFormSaveAndNext();
      }
    }

  }


  const onCompleteFormSubmit = async () => {

    try {
      mutateHandmatigeInvoer(formAfzender, {
        onSuccess: (response) => {
          setResponseContent(JSON.stringify(response, null, 2));
        },
        onError: (response) => {
          setResponseContent(response.message);
          setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.')
        },
      });
    } catch (e) {
      setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.')
    }
  }

  const steps = [
    <Step1 onSubmit={onSubmitStep1} isLoading={handmatigeInvoerIsLoading} isError={handmatigeInvoerIsError} />,
    <Step2 onSubmit={onSubmitStep2} />,
    <Step3 onSubmit={onSubmitStep3} isValidating={isValidating} />,
    <Step4 onSubmit={onSubmitStep4} isValidating={isValidating} />,
    <Step5 onSubmit={onSubmitStep5} isSaving={isSaving} />,
  ];
  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical">
        <Steps
          style={{ marginBottom: '20px', maxWidth: '1000px', margin: '0 auto' }}
          type="navigation"
          size="small"
          current={currentStep}
          onChange={setCurrentStep}
          className="site-navigation-steps"
          items={items}
        />
        <Page title={t(`form.title.${currentStep}`)}>
          <Form onFinish={onCompleteFormSubmit} form={form}>
            {steps[currentStep]}
          </Form>
        </Page>

        <ResponseModal
          responseContent={responseContent}
          error={modalErrorContent}
          onOk={onModalOk}
        />
      </Space>
    </>
  );
};