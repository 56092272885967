import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Lozingen } from './lozingen';
import { Metingen } from './metingen';
import { Lozingsvorm } from './lozingsvorm';
import { Relatie } from '@/lib/types';


export const SubstraatteeltMiliegegevens: FC = () => {
  const form = Form.useFormInstance<Relatie>();
  const [heeftLozing, setHeeftLozing] = useState(false);

  const lozingsVorm = Form.useWatch('lozingsVorm', form) || [];

  useEffect(() => {
    const calculatedHeeftLozing = lozingsVorm.some(
      (item) => item !== undefined && item.betrekkingTotTeeltwijze === 1 && [1, 2, 3].includes(item.value)
    );
    setHeeftLozing(calculatedHeeftLozing);

  }, [lozingsVorm]);

  return (
    <>
      <Lozingsvorm teeltwijze={1} />
      {(heeftLozing) && (
        <>
          <Lozingen teeltwijze={1} />
          <Metingen teeltwijze={1} />
        </>
      )}
    </>
  );
};
