export interface CompanySearchFilter {
  search?: string;
  year?: number;
}

export interface EnvironmentReport {
  teeltPlanId: number;
  uoNumber: string;
  name: string;
  address?: string;
  city?: string;
  review?: Review;
}

export interface AggregatieRapportInput {
  registratieJaar?: number;
  bevoegdGezagId?: number;
  bevoegdGezagTypeCode?: string;
}

export interface Review {
  teeltPlanId: number;
  status: ReviewStatus;
  reviewStatusName: string;
  reason?: string;
  dateOfReview: Date;
}

export enum ReviewStatus {
  Onbekend = 0,
  Onvolledig = 1,
  Goedgekeurd = 2,
  Afgekeurd = 3,
}