import { App, ConfigProvider } from 'antd';

import nlNL from 'antd/locale/nl_NL';

import { MsalProvider } from '@azure/msal-react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureFlagProvider } from './lib/context';
import { UserProvider } from './lib/context/userContext';
import { httpErrorConverter, msalInstance } from './lib/utils';
import theme from './theme';

export const Providers: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { message: messageApi } = App.useApp();
  const { t } = useTranslation('errors');

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: true,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: async (error, query) => {
        if (query.meta?.errorMessage) {
          messageApi.error(query.meta.errorMessage as string);
        } else {
          const message = await httpErrorConverter(error);
          messageApi.error(t(message));
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: async (error, variables, context, mutation) => {
        if (mutation.meta?.errorMessage) {
          messageApi.error(t(mutation.meta.errorMessage as string));
        } else {
          const message = await httpErrorConverter(error);
          messageApi.error(t(message));
        }
      },
    }),
  });

  return (
    <MsalProvider instance={msalInstance}>
      <FeatureFlagProvider>
        <UserProvider>
          <ConfigProvider locale={nlNL} theme={theme}>
            <QueryClientProvider client={queryClient}>
              {children}
              {/* <ReactQueryDevtools initialIsOpen={true} /> */}
            </QueryClientProvider>
          </ConfigProvider>
        </UserProvider>
      </FeatureFlagProvider>
    </MsalProvider>
  );
};
