import { useGetGewassen, useGetSectoren } from '@/lib/queries/manual_input';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Select, Switch, Typography } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../page.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(utc);
dayjs.extend(isoWeek);

interface Gewas {
  code: string;
  omschrijving: string;
  sector: { code: string; omschrijving: string } | null;
}

export const TeeltplanGewassen: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const form = Form.useFormInstance();
  const [targetYear, setTargetYear] = useState<number | null>(null);
  const { data: sectoren, isLoading: sectorenLoading } = useGetSectoren();
  const { data: gewassen, isLoading: gewassenLoading } = useGetGewassen();
  const isGrondteelt = [0].includes(form.getFieldValue('teeltwijze'));
  const isCombiteelt = [2].includes(form.getFieldValue('teeltwijze'));

  const listRows = {
    van: 2,
    tot: 2,
    sector: 5,
    gewas: 5,
    teeltInGrond: 2,
    assimilatie: 3,
    oppervlakte: 4,
    action: 1,
  };


  useEffect(() => {
    const registratiejaar = form.getFieldValue('registratiejaar');
    setTargetYear(registratiejaar);
  }, [form]);

  const weeksOfYear = useMemo(() => {
    if (!targetYear) return [];

    const firstMondayOfISOYear = dayjs.utc(new Date(Date.UTC(targetYear, 0, 4))).startOf('isoWeek');

    // Get the last Sunday of the last ISO 8601 week, which includes December 28th
    const lastSundayOfISOYear = dayjs.utc(new Date(Date.UTC(targetYear, 11, 28))).endOf('isoWeek').subtract(1, 'day');

    const weeks: { weekNumber: number; monday: Dayjs; sunday: Dayjs }[] = [];
    let currentStartDate = firstMondayOfISOYear;
    let weekNumber = 1;

    // Loop to construct each ISO week
    while (currentStartDate.isBefore(lastSundayOfISOYear) || currentStartDate.isSame(lastSundayOfISOYear)) {
      const startOfWeek = currentStartDate;
      const endOfWeek = currentStartDate.add(6, 'day');

      weeks.push({
        weekNumber,
        monday: startOfWeek,
        sunday: endOfWeek,
      });

      // Move to the next ISO week
      currentStartDate = currentStartDate.add(1, 'week');
      weekNumber++;
    }

    return weeks;
  }, [targetYear]);

  useEffect(() => {

    const teeltplanRegels = form.getFieldValue(['teeltplan', 'teeltplanRegel']) || [];

    // Update teeltplanRegels to include the correct sector values if not set
    const updatedTeeltplanRegels = teeltplanRegels.map((regel: any) => {
      const sectorCode = gewassen?.find(
        (g) => g.code === regel.clusterCode
      )?.sector?.code;

      return {
        ...regel,
        sector: sectorCode || regel.sector,
      };
    });

    form.setFieldsValue({
      teeltplan: {
        teeltplanRegel: updatedTeeltplanRegels,
      },
    });
  }, [form, gewassen]);

  useEffect(() => {
    const teeltplanRegels = form.getFieldValue(['teeltplan', 'teeltplanRegel']) || [];

    // Map over each regel and set the corresponding week numbers
    const updatedTeeltplanRegels = teeltplanRegels.map((regel: any) => {
      const beginWeek =
        regel.beginDatumGeldigheid &&
        weeksOfYear.find((week) =>
          dayjs(regel.beginDatumGeldigheid).isSame(week.monday, 'day')
        )?.weekNumber;

      const eindWeek =
        regel.eindDatumGeldigheid &&
        weeksOfYear.find((week) =>
          dayjs(regel.eindDatumGeldigheid).isSame(week.sunday, 'day')
        )?.weekNumber;

      return {
        ...regel,
        beginWeek,
        eindWeek,
      };
    });

    // Update the form with the calculated week numbers
    form.setFieldValue(['teeltplan', 'teeltplanRegel'], updatedTeeltplanRegels);
  }, [form, weeksOfYear]);

  const { sectorGewassen, overigGewassen } = useMemo(() => {
    const sectorMap: { [key: string]: Gewas[] } = {};
    const overig: Gewas[] = [];

    gewassen?.forEach((g) => {
      if (g.sector == null) {
        overig.push(g);
      } else {
        const sectorCode = g.sector.code;
        if (!sectorMap[sectorCode]) {
          sectorMap[sectorCode] = [];
        }
        sectorMap[sectorCode].push(g);
      }
    });

    return { sectorGewassen: sectorMap, overigGewassen: overig };
  }, [gewassen]);

  const handleSectorChange = (value: string, fieldIndex: number) => {
    form.setFieldValue(['teeltplan', 'teeltplanRegel', fieldIndex, 'clusterCode'], undefined);
  };

  const handleWeekChange = (value: number, fieldIndex: number, type: 'begin' | 'eind') => {
    const week = weeksOfYear.find((w) => w.weekNumber === value);
    if (week) {
      if (type === 'begin') {
        form.setFieldValue(['teeltplan', 'teeltplanRegel', fieldIndex, 'beginDatumGeldigheid'], week.monday);
      } else {
        form.setFieldValue(['teeltplan', 'teeltplanRegel', fieldIndex, 'eindDatumGeldigheid'], week.sunday);
      }
    }
  };

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.teeltplan.title')}
      </Title>
      <Form.List initialValue={[]} name={['teeltplan', 'teeltplanRegel']}>
        {(fields, operation) => (
          <>
            <Row style={{ marginBottom: '5px' }}>
              <Col span={listRows.van} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.van')}
              </Col>
              <Col span={listRows.tot} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.tot')}
              </Col>
              <Col span={listRows.sector} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.sector')}
              </Col>
              <Col span={listRows.gewas} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.gewas')}
              </Col>
              <Col span={listRows.teeltInGrond} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.teeltInGrond')}
              </Col>
              <Col span={listRows.assimilatie} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.assimilatie')}
              </Col>
              <Col span={listRows.oppervlakte} style={{ fontWeight: 'bold' }}>
                {t('form.teeltplan.headers.oppervlakte')}
              </Col>
              <Col span={listRows.action}></Col>
            </Row>

            {fields.map((field) => (
              <Row key={field.key} className={styles.rowBorder}>
                <Col span={listRows.van}>
                  <Form.Item
                    rules={[{ required: true, message: '' }]}
                    name={[field.name, 'beginWeek']}
                  >
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      onChange={(value) => handleWeekChange(value, field.name, 'begin')}
                    >
                      {weeksOfYear.map((week) => (
                        <Select.Option key={week.weekNumber} value={week.weekNumber}>
                          {week.weekNumber}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* Hidden field to store the computed begin date */}
                  <Form.Item name={[field.name, 'beginDatumGeldigheid']} hidden>
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={listRows.tot}>
                  <Form.Item
                    rules={[{ required: true, message: '' }]}
                    name={[field.name, 'eindWeek']}
                  >
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      onChange={(value) => handleWeekChange(value, field.name, 'eind')}
                    >
                      {weeksOfYear.map((week) => (
                        <Select.Option key={week.weekNumber} value={week.weekNumber}>
                          {week.weekNumber}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* Hidden field to store the computed end date */}
                  <Form.Item name={[field.name, 'eindDatumGeldigheid']} hidden>
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={listRows.sector}>
                  <Form.Item rules={[{ required: true, message: '' }]} name={[field.name, 'sector']}>
                    <Select
                      loading={sectorenLoading}
                      showSearch
                      optionFilterProp='children'
                      onChange={(value) => handleSectorChange(value, field.name)}
                      style={{ width: '90%' }}
                    >
                      {/* Render available sectors, sorted alphabetically */}
                      {sectoren
                        ?.slice()
                        .sort((a, b) => a.omschrijving.localeCompare(b.omschrijving))
                        .map((sector) => (
                          <Select.Option key={sector.code} value={sector.code}>
                            {sector.omschrijving}
                          </Select.Option>
                        ))}
                      {/* Add "Overig" option */}
                      <Select.Option key="overig" value="Overig">
                        Overig
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={listRows.gewas}>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Form.Item
                        rules={[{ required: true, message: '' }]}
                        name={[field.name, 'clusterCode']}
                      >
                        <Select
                          loading={gewassenLoading}
                          showSearch
                          optionFilterProp='children'
                          style={{ width: '90%' }}
                        >
                          {(() => {
                            const sectorCode = form.getFieldValue([
                              'teeltplan',
                              'teeltplanRegel',
                              field.name,
                              'sector',
                            ]);
                            const selectedGewassen =
                              sectorCode === 'Overig'
                                ? overigGewassen
                                : sectorGewassen[sectorCode] || [];

                            return selectedGewassen
                              .slice()
                              .sort((a, b) => a.omschrijving.localeCompare(b.omschrijving))
                              .map((gewas) => (
                                <Select.Option key={gewas.code} value={gewas.code}>
                                  {gewas.omschrijving}
                                </Select.Option>
                              ));
                          })()}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={listRows.teeltInGrond}>
                  <Form.Item
                    name={[field.name, 'teeltInGrond']}
                    valuePropName="checked"
                    initialValue={isGrondteelt}
                  >
                    <Switch disabled={!isCombiteelt} />
                  </Form.Item>
                </Col>
                <Col span={listRows.assimilatie}>
                  <Form.Item
                    name={[field.name, 'teeltInGrondMetAssimilatieBelichting']}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={listRows.oppervlakte}>
                  <Form.Item
                    rules={[
                      { required: true, message: '' },
                      { type: 'number', min: 1, message: 'Waarde mag niet 0 zijn' },
                    ]}
                    name={[field.name, 'oppervlakte']}
                  >
                    <InputNumber step={1} min={0} max={999999999} addonAfter={'m²'} />
                  </Form.Item>
                </Col>
                <Col span={listRows.action}>
                  <Button
                    onClick={() => operation.remove(field.name)}
                    icon={<DeleteOutlined />}
                  />
                </Col>
              </Row>
            ))}

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const teeltplanRegel = getFieldValue(['teeltplan', 'teeltplanRegel']) || [];
                const hasLowOppervlakte = teeltplanRegel.some(
                  (regel) => regel.oppervlakte !== undefined && regel.oppervlakte < 100
                );

                return (
                  hasLowOppervlakte && (
                    <Row justify="end" style={{ color: 'orange', fontSize: '14px', marginTop: '10px', marginBottom: '10px' }}>
                      <Col>
                        {t('form.teeltplan.warnings.oppervlakteLow', { minimum: 100 })}
                      </Col>
                    </Row>
                  )
                );
              }}
            </Form.Item>

            <Button onClick={() => operation.add()} type="primary">
              Gewas toevoegen
            </Button>

            <br />
          </>
        )}
      </Form.List>
    </>
  );
};