import { PageWidget } from '@/lib';
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Authenticator from '@/assets/authenticator-app.png';
import QRAuthenticator from '@/assets/authenticator-qr.png';
import CodeAuthenticator from '@/assets/authenticator-code.png';

export const MaintenanceWidget: React.FunctionComponent = () => {
  const { t } = useTranslation('core');
  return (
    <div style={{ maxWidth: '800px' }}>
      <PageWidget padding={'1rem'}>
        <Typography.Title level={1}>{t('maintenance.title')}</Typography.Title>
        <Typography.Paragraph>{t('maintenance.introParagraph')}</Typography.Paragraph>
        <Typography.Paragraph style={{ color: 'red' }}>{t('maintenance.details1')}</Typography.Paragraph>
        <Typography.Paragraph>{t('maintenance.larsDetails')}</Typography.Paragraph>
      </PageWidget>
    </div>
  );
};