import { Button, Form } from 'antd';
import { Bedrijfsgegevens } from './components';
import { InfoBullets } from '../step_2/components';

export const Step2: React.FunctionComponent<{ onSubmit: () => void; }> = ({ onSubmit }) => {

  return (
    <>
      <InfoBullets />
      <Bedrijfsgegevens />
      <Button style={{ marginTop: '10px' }} onClick={onSubmit} type="primary">
        Volgende
      </Button>
    </>
  )
}