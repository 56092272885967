import { Form, Typography, Select, Input } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LozingsVormInputDto {
  betrekkingTotTeeltwijze: number;
  value: number;
}

interface Properties {
  teeltwijze: number;
}

export const Lozingsvorm: FC<Properties> = ({ teeltwijze }) => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const form = Form.useFormInstance();

  const [lozingsVormList, setLozingsVormList] = useState<LozingsVormInputDto[]>([]);

  useEffect(() => {
    const existingLozingsVorm = form.getFieldValue('lozingsVorm') || [];

    const hasLozingsVorm = existingLozingsVorm.some(
      (item: LozingsVormInputDto) => item.betrekkingTotTeeltwijze === teeltwijze
    );

    if (!hasLozingsVorm) {
      const newLozingsVormInputDto = {
        betrekkingTotTeeltwijze: teeltwijze,
        value: undefined,
      };
      const updatedLozingsVorm = [...existingLozingsVorm, newLozingsVormInputDto];

      form.setFieldsValue({
        lozingsVorm: updatedLozingsVorm,
      });

      setLozingsVormList(updatedLozingsVorm);
    } else {
      setLozingsVormList(existingLozingsVorm);
    }
  }, [teeltwijze, form]);

  const currentLozingIndex = lozingsVormList.findIndex(
    (item) => item.betrekkingTotTeeltwijze === teeltwijze
  );

  const handleSelectChange = (value: number) => {
    const updatedLozingsVorm = lozingsVormList.map((item, index) =>
      index === currentLozingIndex ? { ...item, value } : item
    );
    setLozingsVormList(updatedLozingsVorm);
  };

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.otherCompanyInfo.drainMethod.title')}
      </Title>
      <Typography>
        {teeltwijze == 0 && t('form.otherCompanyInfo.drainMethod.subtitleGrondteelt')}
        {teeltwijze == 1 && t('form.otherCompanyInfo.drainMethod.subtitleSubstraatteelt')}
      </Typography>

      {currentLozingIndex !== -1 && (
        <>
          {/* Hidden field to store teeltwijze in lozingsVorm */}
          <Form.Item
            name={['lozingsVorm', currentLozingIndex, 'betrekkingTotTeeltwijze']}
            initialValue={teeltwijze}
            hidden
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={['lozingsVorm', currentLozingIndex, 'value']}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              onChange={handleSelectChange}
              value={lozingsVormList[currentLozingIndex]?.value}
              style={{ width: '30%' }}
            >
              <Select.Option value={0}>{t('form.otherCompanyInfo.drainMethod.option_1')}</Select.Option>
              <Select.Option value={1}>{t('form.otherCompanyInfo.drainMethod.option_2')}</Select.Option>
              <Select.Option value={2}>{t('form.otherCompanyInfo.drainMethod.option_3')}</Select.Option>
              <Select.Option value={3}>{t('form.otherCompanyInfo.drainMethod.option_4')}</Select.Option>
              <Select.Option value={4} disabled={teeltwijze == 1}>{t('form.otherCompanyInfo.drainMethod.option_5')}</Select.Option>
            </Select>
          </Form.Item>
        </>
      )}
    </>
  );
};