import { Modal, Typography, List, Alert } from 'antd';


const ResponseModal = ({ responseContent: response, error, onOk }) => {

  let responseData: any = null;
  if (response) {
    try {
      responseData = JSON.parse(response);
    } catch (e) {
      console.error('Failed to parse responseContent:', e);

    }
  }
  return (
    <Modal
      open={!!response || !!error}
      title={error ? 'Er is iets mis gegaan' : 'Resultaat van het bericht'}
      closable={false}
      onOk={onOk}
      footer={[
        <button key="ok" type="button" onClick={onOk} style={{ padding: '8px 16px', backgroundColor: '#1890ff', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          OK
        </button>,
      ]}
    >
      {error ? (
        <Alert message={error} type="error" showIcon />
      ) : responseData ? (
        <div>
          {responseData.relaties && responseData.relaties.length > 0 ? (
            responseData.relaties.map((relatie, index) => (
              <div key={index} style={{ marginBottom: '16px' }}>
                {relatie.foutBerichten && relatie.foutBerichten.length > 0 ? (
                  <List
                    dataSource={relatie.foutBerichten}
                    renderItem={(foutBericht: any) => (
                      <List.Item>
                        <Alert
                          message={`${foutBericht.resultType == "Critical" ? 'Validatie fout' : foutBericht.resultType == "Warning" ? 'Waarschuwing' : 'Gelukt!'}`}
                          description={
                            <>
                              <p>{foutBericht.omschrijving}</p>
                            </>
                          }
                          type={foutBericht.resultType == "Warning" ? "warning" : foutBericht.resultType == "Critical" ? "error" : foutBericht.resultType == "OK" ? "success" : "error"}
                          style={{ width: '100%' }}
                          showIcon
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <Alert message="Geen fouten gevonden" type="success" showIcon />
                )}
              </div>
            ))
          ) : (
            <p>Geen relaties gevonden in de respons.</p>
          )}
        </div>
      ) : (
        <p>Geen respons beschikbaar.</p>
      )}
    </Modal>
  );
};

export default ResponseModal;