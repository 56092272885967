import { MainHeader, SideNav, loginRequest, resetPasswordRequest } from '@/lib';
import { EventType, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { Layout, Skeleton } from 'antd';
import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './layout.module.scss';

export const BaseLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, inProgress } = useMsal();

  const account = useAccount();

  // Redirect to custom reset password flow upon error AADB2C90118
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.message.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(resetPasswordRequest);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(resetPasswordRequest).catch((e) => {
              console.error("Error during popup login for password reset:", e);
            });
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  const onLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const onLogout = () => {
    instance.logoutRedirect({
      account: account,
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Skeleton loading={inProgress === InteractionStatus.HandleRedirect}>
      <Layout className={styles.main}>
        <header className={styles.header}>
          <MainHeader onLogin={onLogin} onLogout={onLogout} isAuthenticated={!!account} />
        </header>
        <aside className={styles.sider}> {account ? <SideNav selectedKey={location.pathname} onSelect={navigate} /> : null}</aside>
        <main className={styles.content}>
          <Suspense fallback={<Skeleton active loading />}>
            <Outlet />
          </Suspense>
        </main>
      </Layout>
    </Skeleton>
  );
};