import { Row, Col, Form, InputNumber, Typography, Button, Modal } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

export const FosforStikstof: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.groundEmission.title')}
      </Title>
      <Typography>{t('form.groundEmission.subtitle')}</Typography>
      <Row>
        <Col span={3}>
          {t('form.groundEmission.stikstof')}
        </Col>
        <Col span={3}>
          {t('form.groundEmission.fosfor')}
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <Form.Item rules={[{ required: true, message: '' }]} name={['verbruiksGegevens', 'verbruikN']}>
            <InputNumber min={0} step={0.1} max={999999999.9} style={{ width: '80%' }} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item rules={[{ required: true, message: '' }]} name={['verbruiksGegevens', 'verbruikP']}>
            <InputNumber min={0} step={0.1} max={99999999.9} style={{ width: '80%' }} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button onClick={showModal} type="primary" icon={<InfoCircleOutlined />}>
            {t('form.hulptabel.title')}
          </Button>
        </Col>
      </Row>
      <Modal title={t('form.hulptabel.title')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};