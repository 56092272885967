import { Flex, Form, InputNumber, Input, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LozingenInputDto {
  betrekkingTotTeeltwijze: number;
  lozingen: { periode: number; hoeveelheid?: number }[];
}

interface Properties {
  teeltwijze: number;
}

export const Lozingen: FC<Properties> = ({ teeltwijze }) => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const form = Form.useFormInstance();

  const [lozingenList, setLozingenList] = useState<LozingenInputDto[]>([]);

  useEffect(() => {
    const existingLozingen = form.getFieldValue('lozingen') || [];

    const hasLozing = existingLozingen.some(
      (l: LozingenInputDto) => l.betrekkingTotTeeltwijze === teeltwijze
    );

    if (!hasLozing) {
      const newLozingenInputDto: LozingenInputDto = {
        betrekkingTotTeeltwijze: teeltwijze,
        lozingen: Array.from({ length: 13 }, (_, index) => ({
          periode: index + 1,
          hoeveelheid: undefined,
        })),
      };
      const updatedLozingen = [...existingLozingen, newLozingenInputDto];

      form.setFieldsValue({
        lozingen: updatedLozingen,
      });

      setLozingenList(updatedLozingen);
    } else {
      setLozingenList(existingLozingen);
    }
  }, [teeltwijze, form]);

  const currentLozingIndex = lozingenList.findIndex(
    (l) => l.betrekkingTotTeeltwijze === teeltwijze
  );

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {teeltwijze === 0 && t('form.emissionReport.volumes.titleGrondteelt')}
        {teeltwijze === 1 && t('form.emissionReport.volumes.titleSubstraatteelt')}
      </Title>
      <Typography>
        {teeltwijze == 0 && t('form.emissionReport.volumes.typeGrondteelt')}
        {teeltwijze == 1 && t('form.emissionReport.volumes.typeSubstraatteelt')}
      </Typography>
      {currentLozingIndex !== -1 && (
        <>
          <Form.Item
            name={['lozingen', currentLozingIndex, 'betrekkingTotTeeltwijze']}
            initialValue={teeltwijze}
            hidden
          >
            <Input />
          </Form.Item>

          <Form.List name={['lozingen', currentLozingIndex, 'lozingen']}>
            {(fields) => (
              <Flex wrap align="start" style={{ width: '80%' }}>
                {fields.map((field) => (
                  <Form.Item
                    key={field.key}
                    style={{ padding: 5, margin: 0 }}
                    name={[field.name, 'hoeveelheid']}
                    labelCol={{ span: 11 }}
                    rules={[{ required: true, message: '' }]}
                    label={
                      <span style={{ width: '110px', textAlign: 'left', paddingLeft: '5px' }}>
                        {`Periode ${field.name + 1}`}
                      </span>
                    }
                  >
                    <InputNumber min={0} step={1} precision={0} max={999999999.9} style={{ width: '100px', float: 'right' }} addonAfter="m³" />
                  </Form.Item>
                ))}
              </Flex>
            )}
          </Form.List>
        </>
      )}
    </>
  );
};