import { Button, Form, Typography } from 'antd';
import { InfoBullets } from './components';
import { Relatie } from '@/lib/types';
import { GrondteeltMilieugegevens } from './components/grondteelt-milieugegevens';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { Teeltwijze } from './components/teeltwijze';
import { SubstraatteeltMiliegegevens } from './components/substraatteelt-milieugegevens';
import { Waterlozing } from './components/substraatteeltlozingsvorm';
import { Controlevragen } from './components/controlevragen';

export const Step3: React.FunctionComponent<{ onSubmit: () => void; isValidating: boolean }> = ({ onSubmit, isValidating }) => {
  const { t } = useTranslation('input');
  const form = Form.useFormInstance<Relatie>();

  const isGrondTeelt = [0].includes(Form.useWatch(['teeltwijze'], form));
  const isSubstraatTeelt = [1].includes(Form.useWatch(['teeltwijze'], form));
  const isCombiTeelt = [2].includes(Form.useWatch(['teeltwijze'], form));

  return (
    <>
      <InfoBullets />
      <Teeltwijze />
      {isGrondTeelt && (
        <>
          <Controlevragen />
          <Waterlozing />
          <GrondteeltMilieugegevens />
        </>
      )}
      {isSubstraatTeelt && (
        <>
          <Controlevragen />
          <Waterlozing />
          <SubstraatteeltMiliegegevens />
        </>
      )}
      {isCombiTeelt && (
        <>
          <Controlevragen />
          <Waterlozing />
          <Title style={{ margin: '10px 0px', paddingTop: '30px' }} level={2}>
            {t('form.combiteelt.titleGrondTeelt')}
          </Title>
          <GrondteeltMilieugegevens />
          <Title style={{ margin: '10px 0px', paddingTop: '30px' }} level={2}>
            {t('form.combiteelt.titleSubstraatTeelt')}
          </Title>
          <SubstraatteeltMiliegegevens />
        </>
      )}
      <br />
      <Button onClick={onSubmit} style={{ marginTop: '20px' }} loading={isValidating} type="primary">
        Valideren en volgende
      </Button>
    </>
  );
};