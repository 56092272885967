import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const InfoBullets: FC = () => {
  const { t } = useTranslation('input');

  return (
    <ul >
      <li>{t('form.info_2.bullet_1')}</li>
    </ul>
  );
};