import Logo from '@/assets/logo.jpg';
import { UserAvatar } from '@/lib/components/core/auth/userAvatar';
import { useUserContext } from '@/lib/context/userContext';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Feature } from '../features/feature';
import styles from './core.module.scss';
import { useNavigate } from 'react-router-dom';
const { Header } = Layout;

interface MainHeaderProps {
  onLogin: () => void;
  onLogout: () => void;
  isAuthenticated: boolean;
}

export const MainHeader: React.FunctionComponent<MainHeaderProps> = ({ onLogin, onLogout, isAuthenticated }) => {
  const { t } = useTranslation('core');
  const { username } = useUserContext();
  const navigate = useNavigate();

  const blobUrl = import.meta.env.VITE_F_BLOB_LOGINMANUAL_URL

  const onPrivacy = () => {
    navigate('/privacy');
  };

  const onManual = async () => {
    if (blobUrl === undefined || blobUrl === null) {
      return;
    }

    try {
      const response = await fetch(blobUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch the file.');
      }
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = blobUrl.substring(blobUrl.lastIndexOf('/') + 1)
      document.body.appendChild(a);
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error during file download:', error);
    }
  }

  return (

    <Header className={styles.header}>
      <div className={styles.logo}>
        <img src={Logo}></img>
      </div>
      {isAuthenticated ? (
        <UserAvatar
          user={username}
          items={[
            {
              key: 1,
              label: t('userMenu.privacy'),
              icon: <InfoCircleOutlined />,
              onClick: () => navigate("/privacy")
            },
            {
              key: 2,
              label: t('userMenu.logout'),
              icon: <UserOutlined />,
              onClick: onLogout,
            },
          ]}
        />
      ) : (
        <div>
          <Button type="default" style={{ marginRight: 10 }} onClick={onPrivacy}>
            {t('userMenu.privacy')}
          </Button>
          <Button type="default" style={{ marginRight: 10 }} onClick={onManual}>
            {t('userMenu.manual')}
          </Button>
          <Feature flag="show-maintenance-mode">
            <Button type="primary" onClick={onLogin}>
              {t('userMenu.login')}
            </Button>
          </Feature>
        </div>
      )}
    </Header>
  );
};