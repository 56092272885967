import { Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const InfoBullets: FC = () => {
  const { t } = useTranslation('input');
  return (
    <>
      <Typography style={{ fontWeight: 'bold' }}>{t('form.info_3.title')}</Typography>
      <Typography>{t('form.info_3.subtitle')}</Typography>
      <ul>
        <li>{t('form.info_3.bullet_1')}</li>
        <li>{t('form.info_3.bullet_2')}</li>
        <li>{t('form.info_3.bullet_3')}</li>
        <li>{t('form.info_3.bullet_4')}</li>
        <li>{t('form.info_3.bullet_5')}</li>
        <li>{t('form.info_3.bullet_6')}</li>
      </ul>
    </>
  );
};
