import { Button } from 'antd';
import { InfoBullets } from './components/infoBullets';
import { TeeltplanGewassen } from './components/teeltplanGewassen';

export const Step4: React.FunctionComponent<{ onSubmit: () => void; isValidating: boolean; }> = ({ onSubmit, isValidating }) => {
  return (
    <>
      <InfoBullets />
      <TeeltplanGewassen />
      <Button style={{ marginTop: '10px' }} loading={isValidating} onClick={onSubmit} type="primary">
        Opslaan en volgende
      </Button>
    </>
  );
};