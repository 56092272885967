import { Typography, Row, Col, Form, Switch } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../page.module.scss';

export const Controlevragen: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;

  const form = Form.useFormInstance();
  const isGrondteelt = [0].includes(Form.useWatch('teeltwijze', form));
  const isSubstraatteelt = [1].includes(Form.useWatch('teeltwijze', form));
  const isCombiteelt = [2].includes(Form.useWatch('teeltwijze', form));

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.otherCompanyInfo.checkQuestions.title')}
      </Title>
      <Row className={styles.rowBorder}>
        <Col span={16}>
          {isGrondteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_1_grondteelt')}
          {isSubstraatteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_1_substraatteelt')}
          {isCombiteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_1_combiteelt')}
        </Col>
        <Col span={4}>
          <Form.Item name={['controle', 'zandfilterDrainage']} rules={[{ required: false, message: '' }]} valuePropName="checked">
            <Switch defaultChecked={false} checkedChildren={'Ja'} unCheckedChildren={'Nee'} />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={16}>
          {isGrondteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_2_grondteelt')}
          {isSubstraatteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_2_substraatteelt')}
          {isCombiteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_2_combiteelt')}
        </Col>
        <Col span={4}>
          <Form.Item name={['controle', 'incidenteleLozing']} rules={[{ required: false, message: '' }]} valuePropName="checked">
            <Switch defaultChecked={false} checkedChildren={'Ja'} unCheckedChildren={'Nee'} />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={16}>
          {isGrondteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_3_grondteelt')}
          {isSubstraatteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_3_substraatteelt')}
          {isCombiteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_3_combiteelt')}
        </Col>
        <Col span={4}>
          <Form.Item name={['controle', 'calamiteit']} rules={[{ required: false, message: '' }]} valuePropName="checked">
            <Switch defaultChecked={false} checkedChildren={'Ja'} unCheckedChildren={'Nee'} />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={16}>
          {isGrondteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_4_grondteelt')}
          {isSubstraatteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_4_substraatteelt')}
          {isCombiteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_4_combiteelt')}
        </Col>
        <Col span={4}>
          <Form.Item name={['controle', 'waterOverschot']} rules={[{ required: false, message: '' }]} valuePropName="checked">
            <Switch defaultChecked={false} checkedChildren={'Ja'} unCheckedChildren={'Nee'} />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={16}>
          {isGrondteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_5_grondteelt')}
          {isSubstraatteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_5_substraatteelt')}
          {isCombiteelt && t('form.otherCompanyInfo.checkQuestions.checkQuestion_5_combiteelt')}
        </Col>
        <Col span={4}>
          <Form.Item name={['controle', 'drainageLozing']} rules={[{ required: false, message: '' }]} valuePropName="checked">
            <Switch defaultChecked={false} checkedChildren={'Ja'} unCheckedChildren={'Nee'} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};