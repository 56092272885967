import { Relatie } from '@/lib/types';
import { Typography, Form, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Teeltwijze: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const form = Form.useFormInstance<Relatie>();

  const clearGewassen = (value) => {
    if (value == 0 || value == 1) {
      form.setFieldValue(['teeltplan', 'teeltplanRegel'], []);
    }
  }

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.otherCompanyInfo.teeltwijze.title')}
      </Title>
      <Typography>{t('form.otherCompanyInfo.teeltwijze.subtitle')}</Typography>
      <Form.Item rules={[{ required: true, message: '' }]} noStyle name={'teeltwijze'}>
        <Select style={{ width: '50%' }} onChange={(e) => clearGewassen(e)}>
          <Select.Option value={0}>{t('form.otherCompanyInfo.teeltwijze.option_1')}</Select.Option>
          <Select.Option value={1}>{t('form.otherCompanyInfo.teeltwijze.option_2')}</Select.Option>
          <Select.Option value={2}>{t('form.otherCompanyInfo.teeltwijze.option_3')}</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};