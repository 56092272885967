import { Form, InputNumber, Radio, Table, Typography, DatePicker } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import { MetingenInputDto } from '@/lib/types';
import updateLocale from 'dayjs/plugin/updateLocale';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';

dayjs.extend(utc);
dayjs.extend(isoWeek);

dayjs.extend(updateLocale);
dayjs.extend(isoWeeksInYear);
// dayjs.updateLocale('en', {
//   weekStart: 1,
// });

const datePickerConfig = dayjsGenerateConfig;
const DatePickerComponent = generatePicker<Dayjs>(datePickerConfig);

interface Properties {
  teeltwijze: number;
}

export const Metingen: FC<Properties> = ({ teeltwijze }) => {
  const { Title } = Typography;
  const form = Form.useFormInstance();
  const registratiejaar = form.getFieldValue('registratiejaar');
  const isGrondteelt = [0].includes(Form.useWatch('teeltwijze', form));
  const isSubstraatteelt = [1].includes(Form.useWatch('teeltwijze', form));
  const isCombiteelt = [2].includes(Form.useWatch('teeltwijze', form));

  const [metingenList, setMetingenList] = useState<MetingenInputDto[]>([]);
  const currentMetingenIndex = metingenList.findIndex((m) => m.betrekkingTotTeeltwijze === teeltwijze);
  const aantalMetingenPerJaar = Form.useWatch(['metingen', currentMetingenIndex, 'aantalMetingenPerJaar'], form);

  const metingOptions =
    isGrondteelt ?
      [
        { key: '0', label: '4', value: 4 },
      ]
      : isSubstraatteelt ?
        [
          { key: '0', label: '7', value: 7 },
          { key: '1', label: '13', value: 13 },
        ]
        : isCombiteelt ?
          [
            { key: '0', label: '4', value: 4 },
            { key: '1', label: '7', value: 7 },
            { key: '2', label: '13', value: 13 },
          ]
          : [{ key: '0', label: '0', value: '0' }];

  useEffect(() => {
    const aantalMetingenPerJaarFormValue = form.getFieldValue(['metingen', currentMetingenIndex, 'aantalMetingenPerJaar']);

    if (!aantalMetingenPerJaarFormValue && metingOptions?.length > 0) {
      form.setFieldsValue({
        metingen: {
          ...form.getFieldValue('metingen'),
          [currentMetingenIndex]: {
            ...form.getFieldValue(['metingen', currentMetingenIndex]),
            aantalMetingenPerJaar: metingOptions[0].value,
          },
        },
      });
    }
  }, [form, currentMetingenIndex, metingOptions]);

  useEffect(() => {
    const existingMetingen: MetingenInputDto[] = form.getFieldValue('metingen') || [];

    const hasMetingen = existingMetingen.some(
      (m: MetingenInputDto) => m.betrekkingTotTeeltwijze === teeltwijze
    );

    if (!hasMetingen) {
      const newMetingenInputDto: MetingenInputDto = {
        betrekkingTotTeeltwijze: teeltwijze,
        aantalMetingenPerJaar: 0,
        nH4Metingen: [],
        nO3Metingen: [],
        pMetingen: [],
        isBerekend: false,
      };

      const updatedMetingen = [...existingMetingen, newMetingenInputDto];

      form.setFieldsValue({ metingen: updatedMetingen });

      setMetingenList(updatedMetingen);
    } else {
      setMetingenList(existingMetingen);
    }
  }, [teeltwijze, form]);

  const startOfISOYear = useMemo(
    () => dayjs.utc(new Date(Date.UTC(registratiejaar!, 0, 4))).startOf('isoWeek'),
    [registratiejaar]
  );
  const endOfISOYear = useMemo(
    () => dayjs.utc(new Date(Date.UTC(registratiejaar!, 11, 28))).endOf('isoWeek').subtract(1, 'day'),
    [registratiejaar]
  );

  const disableOutOfISOYear = (currentDate: Dayjs) => {
    return currentDate.isBefore(startOfISOYear) || currentDate.isAfter(endOfISOYear);
  };

  const data = useMemo(() => {
    return Array.from({ length: aantalMetingenPerJaar }, (_, i) => ({
      key: i,
      periode: i + 1,
    }));
  }, [aantalMetingenPerJaar]);

  const columns: ColumnsType<any> = [
    {
      title: 'Periode',
      dataIndex: 'periode',
      width: '40px',
      key: 'periode',
      render: (_: any, __: any, index: number) => (
        <>
          <Form.Item
            name={['metingen', currentMetingenIndex, 'nH4Metingen', index, 'periode']}
            initialValue={index + 1}
          >
            <span>{index + 1}</span>
          </Form.Item>

          <Form.Item
            name={['metingen', currentMetingenIndex, 'nO3Metingen', index, 'periode']}
            initialValue={index + 1}
            hidden
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name={['metingen', currentMetingenIndex, 'pMetingen', index, 'periode']}
            initialValue={index + 1}
            hidden
          >
            <InputNumber />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Datum',
      dataIndex: 'Datum',
      key: 'Datum',
      render: (_: any, record: any, index: number) => (
        <Form.Item
          name={['metingen', currentMetingenIndex, 'nH4Metingen', index, 'datum']}
          initialValue={null}
          rules={[{ required: false, message: 'Datum is required' }]}
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })} // Ensure value is always a dayjs object
          getValueFromEvent={(value) => value?.toISOString()} // Convert to ISO string on change
        >

          <DatePickerComponent
            format={'DD-MM-YYYY'}
            showWeek={true}
            defaultPickerValue={registratiejaar ? dayjs.utc(new Date(Date.UTC(registratiejaar, 0, 1))) : undefined}
            disabledDate={disableOutOfISOYear}
            style={{ width: '100%' }}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Gehalte NH4 [mmol/L]',
      dataIndex: 'gehalteNH4',
      key: 'gehalteNH4',
      render: (_: any, record: any, index: number) => (
        <Form.Item
          name={['metingen', currentMetingenIndex, 'nH4Metingen', index, 'gehalte']}
          rules={[{ required: false, message: 'Gehalte NH4 is required' }]}
        >
          <InputNumber min={0} decimalSeparator={','} precision={2} step={0.01} max={99999999.9} style={{ width: '100%' }} />
        </Form.Item>
      ),
    },
    {
      title: 'Gehalte NO3 [mmol/L]',
      dataIndex: 'gehalteNO3',
      key: 'gehalteNO3',
      render: (_: any, record: any, index: number) => (
        <Form.Item
          name={['metingen', currentMetingenIndex, 'nO3Metingen', index, 'gehalte']}
          rules={[{ required: false, message: 'Gehalte NO3 is required' }]}
        >
          <InputNumber min={0} decimalSeparator={','} precision={2} step={0.01} max={99999999.9} style={{ width: '100%' }} />
        </Form.Item>
      ),
    },
    {
      title: 'Gehalte P [mmol/L]',
      dataIndex: 'gehalteP',
      key: 'gehalteP',
      render: (_: any, record: any, index: number) => (
        <Form.Item
          name={['metingen', currentMetingenIndex, 'pMetingen', index, 'gehalte']}
          rules={[{ required: false, message: 'Gehalte P is required' }]}
        >
          <InputNumber min={0} decimalSeparator={','} precision={2} step={0.01} max={99999999.9} style={{ width: '100%' }} />
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <Title level={5}>Metingen</Title>
      <Form.Item
        name={['metingen', currentMetingenIndex, 'betrekkingTotTeeltwijze']}
        initialValue={teeltwijze}
        noStyle
      >
        <input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Aantal metingen per jaar"
        name={['metingen', currentMetingenIndex, 'aantalMetingenPerJaar']}
        rules={[{ required: true, message: 'Selecteer het aantal metingen per jaar' }]}
      >
        <Radio.Group
          options={metingOptions}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item >

      {aantalMetingenPerJaar > 0 && (
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="periode"
          bordered
        />
      )
      }
    </>
  );
};