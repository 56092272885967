import { Col, DatePicker, Form, Input, Row, Switch, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from '../../page.module.scss';
import dayjs from "dayjs";

export const VerzendBevestiging: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.confirmationPage.notes.title')}
      </Title>
      <Form.Item name={['verzendBevestiging', 'opmerking']}>
        <TextArea style={{ width: '50%' }} rows={7} cols={12} />
      </Form.Item>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.confirmationPage.truth.title')}
      </Title>
      <Row className={styles.rowBorder}>
        <Col span={4}>{t('form.confirmationPage.truth.name')}</Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={['verzendBevestiging', 'naam']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={4}>{t('form.confirmationPage.truth.city')}</Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={['verzendBevestiging', 'plaats']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={4}>{t('form.confirmationPage.truth.signature')}</Col>
        <Col span={4}>
          <Form.Item initialValue={false} name={['verzendBevestiging', 'isHandtekeningAanwezig']}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.confirmationPage.accepted.title')}
      </Title>
      <Row className={styles.rowBorder}>
        <Col span={4}>{t('form.confirmationPage.accepted.yes')}</Col>
        <Col span={4}>
          <Form.Item rules={[{ required: false, message: '' }]} name={['verzendBevestiging', 'deskundige']} valuePropName="checked">
            <Switch defaultValue={false} />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowBorder}>
        <Col span={4}>{t('form.confirmationPage.accepted.name')}</Col>
        <Col span={4}>
          <Form.Item rules={[{ required: false, message: '' }]} name={['verzendBevestiging', 'deskundigeNaam']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item hidden initialValue={dayjs().format('YYYY-MM-DD')} name={['verzendBevestiging', 'datum']}>
      </Form.Item>
    </>
  );
};