import { CustomDatePicker } from '@/lib/components/core/custom-datepicker/CustomDatepicker';
import { Form, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RegistratiejaarProps {
  isLoading: boolean;
}

export const RegistratieJaar: FC<RegistratiejaarProps> = ({ isLoading }) => {
  const { t } = useTranslation('input');
  const { Title } = Typography;

  const disableFutureYears = (current: Dayjs) => current && current.year() < dayjs().year() - 1;

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.year.title')}
      </Title>
      <Form.Item rules={[{ required: true, message: '' }]} name={'registratiejaar'}>
        <CustomDatePicker picker="year" disabled={isLoading} disabledDate={disableFutureYears} />
      </Form.Item>
    </>
  );
};