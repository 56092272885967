import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { Relatie } from '@/lib/types';
import { FosforStikstof } from './fosfor_stikstof';
import { Lozingsvorm } from './lozingsvorm';
import { Lozingen } from './lozingen';
import { Metingen } from './metingen';

export const GrondteeltMilieugegevens: FC = () => {
  const form = Form.useFormInstance<Relatie>();
  const [heeftLozing, setHeeftLozing] = useState(false);

  const lozingsVorm = Form.useWatch('lozingsVorm', form) || [];

  useEffect(() => {
    const calculatedHeeftLozing = lozingsVorm.some(
      (item) => item !== undefined && item.betrekkingTotTeeltwijze === 0 && [1, 2, 3].includes(item.value)
    );
    setHeeftLozing(calculatedHeeftLozing);

  }, [lozingsVorm]);


  return (
    <>
      <FosforStikstof />
      <Lozingsvorm teeltwijze={0} />
      {heeftLozing && (
        <>
          <Lozingen teeltwijze={0} />
          <Metingen teeltwijze={0} />
        </>
      )}
    </>
  );
};