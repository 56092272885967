import { Button, Form, Spin, Typography } from 'antd';
import { InfoBullets, RegistratieJaar } from './components';
import { Relatie } from '@/lib/types';
import { useFeature } from '@/lib/hooks/useFeature';

export const Step1: React.FunctionComponent<{ onSubmit: () => void; isLoading: boolean; isError: boolean; }> = ({ onSubmit, isLoading, isError }) => {
  const form = Form.useFormInstance<Relatie>();
  const isVolledig = form.getFieldValue('isVolledig');
  const registratiejaar = form.getFieldValue('registratiejaar');

  const reportIsVolledig = isVolledig === true && !isError && !isLoading;
  const reportIsOnvolledig = isVolledig === false && !isError && !isLoading;
  const reportIsEmpty = isVolledig === null && !isError && !isLoading;
  const redenVanAfkeuring = form.getFieldValue('redenVanAfkeuring')

  const isEnabled = useFeature('show-editrapportage-button');

  return (
    <>
      <InfoBullets />
      <RegistratieJaar isLoading={isLoading} />
      {isLoading && (
        <Spin />
      )}
      {reportIsVolledig && (
        <>
          <Typography>Er is al een jaarrapportage ingevoerd over dit jaar en deze is definitief. Wijziging is niet mogelijk.</Typography>
        </>
      )}
      {(reportIsEmpty || reportIsOnvolledig) && (
        <>
          {reportIsOnvolledig && (
            <>
              <Typography style={{ 'color': 'red' }}>Ingediende rapportage is als onvolledig verklaard. Pas uw rapportage aan en dien opnieuw in.</Typography>
              <Typography>Reden van afkeuring: {redenVanAfkeuring}</Typography>
            </>
          )}
          <Button
            style={{ marginTop: '10px' }}
            loading={isLoading}
            disabled={!registratiejaar || isLoading || isError || (reportIsOnvolledig && !isEnabled)}
            onClick={onSubmit}
            type="primary"
          >
            {reportIsEmpty ?
              <>
                Nieuwe rapportage invullen
              </> :
              <>
                Rapportage wijzigen
              </>}
          </Button>
        </>
      )}
    </>
  );
}