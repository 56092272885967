import { FC } from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { AuthLayout } from './routes/authLayout';
import { BaseLayout } from './routes/layout';
import { Home } from './routes/page';
import { EnvironmentReportsPage } from './routes/reports/environment/page';
import { Privacy } from './routes/privacy/page';
import { RequestReportPage } from './routes/reports/aggregate/page';
import { ManualInputPage } from './routes/manual-input/page';
import { Roles, useUserContext } from './lib/context';

const Routes = () => {
  const { roles } = useUserContext();
  const routesConfig = [
    {
      element: <BaseLayout />,
      children: [
        { element: <Home />, path: '/' },
        {
          path: '',
          element: <AuthLayout />,
          children: [
            {
              path: '/reports',
              children: [
                {
                  element: <EnvironmentReportsPage />,
                  path: 'environment',
                },
                {
                  element: <RequestReportPage />,
                  path: 'request',
                },
              ],
            },
            {
              path: '/manual-input',
              element: <ManualInputPage />,
              roles: [Roles.Administrator, Roles.Deelnemer, Roles.Dienstverlener],
            },
          ],
        },
        { path: '/privacy', element: <Privacy /> },
      ],
    },
  ];

  const filterRoutes = (routes, roles) =>
    routes.map((route) => {
      if (route.roles && !route.roles.some((role) => roles.includes(role))) {
        return { ...route, element: <Navigate to="/" /> };
      }
      if (route.children) {
        return { ...route, children: filterRoutes(route.children, roles) };
      }
      return route;
    });
  const filteredRoutes = filterRoutes(routesConfig, roles);
  return useRoutes(filteredRoutes);
};

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};